import { Component, Input } from '@angular/core';

// Отображение ошибок валидации
@Component({
    selector: 'app-form-field-error',
    standalone: true,
    templateUrl: './form-field-error.component.html',
    styleUrls: ['./form-field-error.component.scss'],
})
export class FormFieldErrorComponent {

    // Базовые сообщения
    private readonly DEFAULT_MESSAGES = {
        required: 'Это поле обязательно для заполнения',
    };

    // Ошибки
    @Input() controlErrors!: any;

    // Сообщения валидации
    @Input() messages: {[key: string]: any} = {};

    // Отображаемые ошибки
    public get errors(): string[] {

        const errors = (this.controlErrors) || {} as any;
        const messages = {
            ...this.DEFAULT_MESSAGES,
            ...this.messages,
        } as any;

        if (errors.required) {
            return [messages.required];
        }

        if (errors.customValidation) {
            return [errors.message];
        }

        const arrays = Object.keys(errors)
            .filter(key => errors[key])
            .map(key => Array.isArray(messages[key]) ? messages[key] : [messages[key]]);

        return [].concat.apply([], arrays);
    }
}
