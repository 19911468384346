import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';

// Сервисы
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {ValidationService} from "../../../shared/services/validation.service";
import {CacheService} from "../../../shared/services/cache.service";

// Перечисления
import {DaData} from "../../../shared/enums/da-data.enum";
import {IDriverForm} from "../../../shared/interface/osago-application.interface";
import {ContentService} from "../../../shared/services/content.service";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {
  resetInsurerFromOwner,
  setInsurerFromOwner
} from "../../../shared/functions/setFormDataFromForm";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {FormSwitchComponent} from "../../../shared/components/form-switch/form-switch.component";
import {NgForOf} from "@angular/common";
import {getFormatDate} from "../../../shared/functions/formatDate";

// Интерфейсы

// Хелперы

@Component({
  selector: 'app-owner',
  standalone: true,
  templateUrl: './owner.component.html',
  imports: [
    ReactiveFormsModule,
    AutocompleteComponent,
    FormTextFieldComponent,
    FormDateFieldComponent,
    FormSwitchComponent,
    NgForOf
  ],
  styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit, OnDestroy {
  @Output() submitOwnerForm: EventEmitter<boolean> = new EventEmitter<boolean>;

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              private readonly validationService: ValidationService,
              private readonly cacheService: CacheService,
              public readonly contentService: ContentService,) {
  }

  // Форма страхователя
  public formOwner = (this.formService.form.get('owner') as UntypedFormGroup);
  // Форма страховщика
  public formInsurer = (this.formService.form.get('insurer') as UntypedFormGroup);
  // Форма контактов
  public formContacts = (this.formService.form.get('contacts') as UntypedFormGroup);
  // Policy
  public policy = (this.formService.form.get('policy') as UntypedFormControl);
  // Данные водителей
  public drivers: IDriverForm[] = (this.formService.form.get('drivers') as UntypedFormControl).value;
  // Сообщение валидации для контрола policy
  public policyValidationMessage = {
    required: 'Ознакомьтесь с условиями сервиса и политикой конфиденциальности для того, чтобы перейти на следующий шаг'
  };
  // Индикатор загрузки
  public isLoading = false;
  // DaData системные имена
  public daDataSystemName = DaData;
  private destroy$ = new Subject<void>()
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Показываем таймер
  public isShowTimer = false;
  private previousValue: any;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    // При заходе на роут, делаем плавный переход вверх
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.subscribeOwnerForm();
    // this.checkOtherDriver();
    // this.checkActiveDriver();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  private subscribeOwnerForm(): void {
    this.previousValue = this.formOwner.value; // Инициализация предыдущего значения

    this.subscription.add(
      this.formOwner.valueChanges?.subscribe(() => {
        if (!this.areValuesEqual(this.previousValue, this.formOwner.value)) {
          this.checkActiveDriver();
          this.previousValue = this.formOwner.value;  // Обновление предыдущего значения
        }
      })
    );
  }

  // Метод для сравнения предыдущего и текущего значения формы
  private areValuesEqual(value1: any, value2: any): boolean {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  // public checkOtherDriver(): void {
  //   const {value} = this.formOwner;
  //   const driverIndex = this.formService.drivers.value?.findIndex((driver: IDriverForm) => {
  //     return (driver.driverFirstName === value.ownerFirstName
  //       && driver.driverLastName === value.ownerLastName
  //       && driver.driverMiddleName === value.ownerMiddleName
  //       && getFormatDate(driver.driverBirthDate) === getFormatDate(value.ownerBirthDate))
  //   });
  //   if (driverIndex === -1) {
  //     this.formService.ownerIsOtherDriver = true;
  //   }
  // }

  public checkActiveDriver(): void {
    const {value} = this.formOwner;
    const driverIndex = this.formService.drivers.value?.findIndex((driver: IDriverForm) => {
      return (driver.driverFirstName === value.ownerFirstName
        && driver.driverLastName === value.ownerLastName
        && driver.driverMiddleName === value.ownerMiddleName
        && getFormatDate(driver.driverBirthDate) === getFormatDate(value.ownerBirthDate))
    });
    this.formService.changeDriverIsOwnerOrInsurer(true, 'owner', driverIndex, true);
  }

  // Отправка формы
  public onSubmit(): void {
    this.formOwner.markAllAsTouched();
    this.formInsurer.markAsUntouched();
    scrollToFirstError();
    const isValidCarDataPage = this.validationService.validationCarDataForm;
    const isValidOwnerPage = this.validationService.validationOwnerDataForm;

    if (!isValidCarDataPage || !isValidOwnerPage) {
        return;
    }

    this.submitOwnerForm.emit(true);

    this.subscription.add(
      this.cacheService.saveCache()
        // .pipe(
        //   concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.OwnerScreen))
        // )
        .subscribe()
    );
  }

  // Получаем (Фамилия И.О.) для заголовка в контролле ownerIsInsured
  get getOwnerNameTitle(): string {
    const {value} = this.formOwner;
    // Возвращаем значение, только в случае если страхователь является собственником авто
    if (this.formService.ownerIsInsured.value
      && value.ownerLastName
      && value.ownerFirstName?.length
      && value.ownerFirstName[0]) {
      return '(' + value.ownerLastName + ' ' + value.ownerFirstName[0] + '.' + (value.ownerMiddleName ? ' ' + value.ownerMiddleName[0] + '.)' : ')');
    } else {
      return '';
    }
  }

  // Получаем (Фамилия И.О.) для заголовка в блоке выбора водителей
  public getDriverTitle(driver: any): string {
    // Возвращаем значение, только в случае если страхователь является собственником авто
    if (driver.driverLastName
      && driver.driverFirstName?.length
      && driver.driverFirstName[0]) {
      const lastName = driver.driverLastName;
      const firstName = driver.driverFirstName[0] + '.';
      const middleName = driver.driverMiddleName?.length && driver.driverMiddleName[0] ? driver.driverMiddleName[0] + '.' : '';
      return lastName + ' ' + firstName + ' ' + middleName;
    } else {
      return '';
    }
  }

  // Выбрать водителя
  public selectDriver(driverIndex?: number): void {
    // Снимаем метки с формы собственника что бы не показывать валидацию после выбора водителя
    // this.formOwner.markAsUntouched();
    this.formService.changeDriverIsOwnerOrInsurer(true, 'owner', driverIndex);
  }

  // Выбрали "Собственник является страхователем"
  public changeOwnerIsInsurer(event: boolean): void {
    console.log('changeOwnerIsInsurer', event);
    const {value} = this.formOwner;
    this.formService.drivers.controls.forEach((driver, index) => {
      const activeDriver = (driver.value.driverFirstName === value.ownerFirstName
        && driver.value.driverLastName === value.ownerLastName
        && driver.value.driverMiddleName === value.ownerMiddleName
        && driver.value.driverBirthDate === value.ownerBirthDate);

      if (activeDriver) {
        driver.get('driverIsInsurer')?.setValue(event, { emitEvent: false });
      } else {
        driver.get('driverIsInsurer')?.setValue(false, { emitEvent: false });
      }
      if (event) {
        setInsurerFromOwner(this.formOwner.value, this.formInsurer);
      } else {
        resetInsurerFromOwner(this.formInsurer);
      }
    });

    const findDriverIsInsurer = this.formService.drivers.value.find((driver: IDriverForm) => driver.driverIsInsurer);
    // Если такой водитель не найден, значит это "Другой"
    this.formService.insurerIsOtherDriver = !findDriverIsInsurer;
  }

}
