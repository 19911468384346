@if (offer.upsales && offer.upsales.length) {
  <div class="cross">
    @if (isDisabled) {
      @for (upsale of osagoService.selectedOffer!.upsales; track upsale) {
        @if (upsale.isSelected && upsale?.isApproved === null || upsale?.isApproved) {
          <hr class="mb-0 mt-3 opacity-100">
          <div class="cross-description pt-3">
            @if (!osagoService.paymentLink) {
              <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            }
            Включено в стоимость</div>
        }
      }
    } @else if (!isDisabled) {
      <hr class="mb-0 mt-3 opacity-100">
      <div class="cross-description pt-3">
        @if (offer.useUpsales) {
          Включено в стоимость
        } @else {
          Добавить к полису ОСАГО дополнительную защиту КАСКО
        }
      </div>
    }
    <div class="row mt-1">
      @for (upsaleItem of offer.upsales; track upsaleItem) {
        @if (isDisabled && upsaleItem.isSelected) {
          <app-upsale-item [offer]="offer" [isDisabled]="isDisabled" [upsaleItem]="upsaleItem" (checkedChange)="onCheckedChange(upsaleItem.id, $event)"></app-upsale-item>
        } @else if (!isDisabled) {
          <app-upsale-item [offer]="offer" [isDisabled]="isDisabled" [upsaleItem]="upsaleItem" (checkedChange)="onCheckedChange(upsaleItem.id, $event)"></app-upsale-item>
        }
      }
    </div>
  </div>
}

<!--<pre>{{selectedUpsale | json}}</pre>-->
