import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';

// Сервисы

// Перечисления
import {NavigationService} from "../../services/navigation.service";
import {OsagoService} from "../../services/osago.service";
import {PriceComponent} from "../../../components/offers/offer-item/price/price.component";
import {DraftComponent} from "../draft/draft.component";
import {
  ErrorUpsaleComponent
} from "../../../components/offers/offer-item/upsales/upsale-item/error-upsale/error-upsale.component";
import {UpsalesComponent} from "../../../components/offers/offer-item/upsales/upsales.component";
import {environment} from "@environment/environment";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-selected-offer',
  standalone: true,
  templateUrl: './selected-offer.component.html',
  imports: [
    PriceComponent,
    DraftComponent,
    ErrorUpsaleComponent,
    UpsalesComponent
  ],
  styleUrls: ['./selected-offer.component.scss']
})
export class SelectedOfferComponent implements OnInit, OnDestroy {

  @Output() emitOnChangeCreatePolicy = new EventEmitter<void>();
  // Ссылка на оплату
  @Input() paymentLink!: string;

  private readonly route = inject(ActivatedRoute);

  // Параметр fromFastProlongation если клиент пришел из быстрой пролонгации
  public fromFastProlongation!: boolean;

  public readonly osagoService = inject(OsagoService)

  protected readonly environment = environment;

  // Подписка на контрол
  private subscription: Subscription = new Subscription();

  // Выбраннй оффер
  public isPaymentCountdownStarted = false;
  public paymentCountdown = '02:30';
  private countdownInterval: any;
  // Показывать кнопку оплаты
  public isShowPaymentBtn = true;


  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.fromFastProlongation = this.route.snapshot.queryParamMap.get('fromFastProlongation') === 'true';
    if (!this.fromFastProlongation) {
      this.startPaymentCountdown();
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  public onCreatePolicy(): void {
    this.emitOnChangeCreatePolicy.emit();
    this.startPaymentCountdown();
  }

  // Счетчик
  public startPaymentCountdown(): void {
    this.isPaymentCountdownStarted = true;

    // начинаем обратный отсчет
    this.countdownInterval = setInterval(() => {
      const timeParts = this.paymentCountdown.split(':');
      let minutes = +timeParts[0];
      let seconds = +timeParts[1];

      if (minutes === 0 && seconds === 0) {
        clearInterval(this.countdownInterval);
        this.isPaymentCountdownStarted = false;
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  // Открываем страницу оплаты
  public pay(): void {
    this.isShowPaymentBtn = false;
    this.subscription.add(
      this.osagoService.setStatusBuyButtonPressed()
        .subscribe(() => {
          //Открыть ссылку в новом окне
          // window.open(this.osagoService.paymentLink, '_blank');
          setTimeout(() => {
            this.isShowPaymentBtn = true;
          }, 4000);
        })
    );

  }
}
