<h3 class="page-title mt-4 mb-4">Создание полиса</h3>

<app-policy-data [isCollapse]="true"></app-policy-data>

@if (isPaymentLinkResult) {
  <app-selected-offer
    [paymentLink]="osagoService.paymentLink"
    (emitOnChangeCreatePolicy)="getPaymentLink()"></app-selected-offer>
} @else {
  <app-error-payment-link></app-error-payment-link>

  <h3 class="page-title mt-4 mb-4">Другие предложения</h3>

  <app-timer [isLoading]="isLoading" [min]="3" [sec]="30"></app-timer>

  <div class="offers-list">
    @for(offer of osagoService.offers; track offer.offerId) {
      @if (!offer.isSelected) {
        <app-offer-item [offer]="offer"></app-offer-item>
      }
    }
  </div>

  @if (isLoading) {
    <app-offer-placeholder></app-offer-placeholder>
  }

}

<ng-template #templateSuccessPaymentLink>
  <div class="modal-body">
    <h4 class="modal-title pull-left">Ваш полис готов</h4>
    <div>
      На Вашу электронную почту отправлено письмо с образцом полиса и ссылкой на оплату.
      <br>
      Нажмите "Перейти к оплате", чтобы ознакомиться с образцом полиса и оплатить сейчас.
    </div>
    <div class="row mt-4 d-flex justify-content-center">
      <div class="col-12 col-sm-auto">
        <button type="button" class="btn btn-primary btn-lg w-100" (click)="modalRef?.hide()">ОК</button>
      </div>
    </div>
  </div>
</ng-template>
