import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {OsagoService} from "../../shared/services/osago.service";
import {concatMap, EMPTY, filter, iif, of, Subject, Subscription} from "rxjs";
import {finalize, switchMap, tap} from "rxjs/operators";
import {AppService} from "../../shared/services/app.service";
import {FormService} from "../../shared/services/form.service";
import {TimerService} from "../../shared/services/timer.service";
import {ViewportScroller} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {PolicyDataComponent} from "../../shared/components/policy-data/policy-data.component";
import {FormPolicyDateComponent} from "./form-policy-date/form-policy-date.component";
import {KbmComponent} from "./kbm/kbm.component";
import {TimerComponent} from "../../shared/components/timer/timer.component";
import {OfferItemComponent} from "./offer-item/offer-item.component";
import {OfferPlaceholderComponent} from "../../shared/components/offer-placeholder/offer-placeholder.component";
import {EmptyOffersListComponent} from "./empty-offers-list/empty-offers-list.component";
import {ROUTES} from "../../shared/enums/routes.enum";
import {NavigationService} from "../../shared/services/navigation.service";

@Component({
  selector: 'app-offers',
  standalone: true,
  templateUrl: './offers.component.html',
  imports: [
    PolicyDataComponent,
    FormPolicyDateComponent,
    KbmComponent,
    TimerComponent,
    OfferItemComponent,
    OfferPlaceholderComponent,
    EmptyOffersListComponent
  ],
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {
  private viewportScroller = inject(ViewportScroller);
  private navigationService = inject(NavigationService)
  private router = inject(Router);

  constructor(public readonly osagoService: OsagoService,
              private readonly appService: AppService,
              public readonly formService: FormService,
              public readonly timerService: TimerService,
              private readonly ym: YandexMetrikaService) {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.OffersPage);
    osagoService.offers = [];
    osagoService.selectedOffer = null;

    if (this.formService.form.invalid) {
      this.navigationService.navigate(ROUTES.MainPage)
    } else {
      this.loadOffers();
    }
  }

  // Подписка
  private subscription: Subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // Стартуем поиск офферов
  public getOffersFinish!: boolean;
  private policyStartDateChanged = new Subject<string>();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.viewportScroller.scrollToPosition([0, 0]));
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.policyStartDateChanged.complete();
  }

  // --------------------------------------------------------------------------

  // Выбрали дату
  public changePolicyStartDate(event: string): void {
    this.policyStartDateChanged.next(event);
  }

  private loadOffers(): void {
    this.subscription.add(
      this.formService.form.get('policyStartDate')?.valueChanges.pipe(
        switchMap((newDate) => {
          this.timerService.startTimer();
          this.osagoService.offers = [];
          this.getOffersFinish = false;
          this.isLoading = true;

          return iif(
            () => !this.appService.setWidgetDisplayedStatus,
            this.appService.createApplication(),
            of(EMPTY)
          ).pipe(
            concatMap(() => this.osagoService.sendOsagoApplication()),
            filter((res) => {
              // Если данные формы невалидны, то переходим на страницу с формой
              if (res && !res.result) {
                this.navigationService.navigate(ROUTES.Form);
              }
              return res;
            }),
            concatMap(() => {
              return this.osagoService.sendToInsurersGetOffers().pipe(
                finalize(() => {
                  this.isLoading = false;
                  if (this.osagoService.offers && this.osagoService.offers.length === 0) {
                    this.getOffersFinish = true;
                  }
                })
              );
            })
          );
        })
      ).subscribe({
        next: (response) => {
          if (response.result === false) {
          }
        },
        error: () => {}
      })
    );
  }

}
