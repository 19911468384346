import {
  AfterViewInit,
  Component,
  ElementRef, inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormService} from "../../shared/services/form.service";
import {ValidationService} from "../../shared/services/validation.service";
import {AccordionModule, AccordionPanelComponent} from "ngx-bootstrap/accordion";
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ContentService} from "../../shared/services/content.service";
import {AccordionService} from "../../shared/services/accordion.service";
import {CacheService} from "../../shared/services/cache.service";
import {TypeOrganisation} from "../../shared/enums/type-organisation";
import {AccordionHeaderComponent} from "../../shared/components/accordion-header/accordion-header.component";
import {AutoparkComponent} from "./autopark/autopark.component";
import {CommonModule} from "@angular/common";
import {PrivacyLicenseComponent} from "../../shared/components/privacy-license/privacy-license.component";
import {TestComponent} from "../../shared/components/test/test.component";
import {CarDataComponent} from "./car-data/car-data.component";
import {DriverComponent} from "./drivers/driver/driver.component";
import {OwnerComponent} from "./owner/owner.component";
import {InsurerComponent} from "./insurer/insurer.component";
import {LegalInsurerComponent} from "./legal-insurer/legal-insurer.component";
import {LegalOwnerComponent} from "./legal-owner/legal-owner.component";
import {ContactsComponent} from "./contacts/contacts.component";

@Component({
  selector: 'app-form',
  standalone: true,
  templateUrl: './form.component.html',
  imports: [
    AccordionModule,
    AccordionHeaderComponent,
    AutoparkComponent,
    PrivacyLicenseComponent,
    TestComponent,
    CarDataComponent,
    DriverComponent,
    OwnerComponent,
    InsurerComponent,
    LegalInsurerComponent,
    LegalOwnerComponent,
    ContactsComponent,
    CommonModule,
  ],
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('accordion', { static: false, read: ElementRef }) accordionElementRef!: ElementRef;
  @ViewChildren(AccordionPanelComponent) accordionGroups!: QueryList<AccordionPanelComponent>;

  public accordionService = inject(AccordionService);

  constructor(public readonly formService: FormService,
              public readonly validationService: ValidationService,
              public readonly contentService: ContentService,
              public readonly cacheService: CacheService) {
  }

  protected readonly TypeOrganisation = TypeOrganisation;
  // Форма
  public formDrivers = (this.formService.form.get('drivers') as UntypedFormArray);
  // Индикатор загрузки
  public isLoading = false;
  // Подписка
  public subscription = new Subscription();
  // Флаг - водители без ограничений
  public driversWithoutRestriction = (this.formService.form.get('hasDriversRestriction') as UntypedFormControl);
  protected readonly localStorage = localStorage;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
  }

  public ngAfterViewInit() {
    this.accordionService.accordionGroups = this.accordionGroups;
    this.accordionService.accordionElementRef = this.accordionElementRef;
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.accordionService.accordionGroups.get(0)!.isOpen = true;
  }
  // --------------------------------------------------------------------------

  // Получаем FormGroup водителя по индексу
  public driverForm(index: number): UntypedFormGroup {
    return this.formDrivers.at(index) as UntypedFormGroup;
  }

  // Добавить водителя
  public addDriver(): void {
    this.formService.addDriver();
    setTimeout(() => {
      this.accordionService.isOpenAccordionItem(true, `driverGroup${this.formDrivers.length - 2}`, `driverGroup${this.formDrivers.length - 1}`);
    }, 100)
  }

  // Показываем кнопку добавления водителей
  // Если находимся на этапе заполнения формы собственника, страхователя или контактов
  // так же если водителей меньше 5 и не выбрано "Без ограничений"
  get isAddDriverAccItem() {
    return this.accordionService.accordionGroups?.find((item) => item.isOpen
      && (item.panelClass === 'ownerGroup'
        || item.panelClass === 'insurerGroup'
        || item.panelClass === 'contactsGroup'))
      && this.formDrivers.length < 5
      && !this.formService.form.get('driversWithoutRestriction')?.value;
  }

  // Сохраняем кэш
  saveCache(): void {
    this.cacheService.saveCache().subscribe(() => {

    });
  }
  // Сбрасываем кэш
  resetCache(): void {
    this.subscription.add(
      this.cacheService.resetCache()
        .subscribe(() => {
          this.formService.form.markAsPristine();
          this.formService.form.markAsUntouched();
        })
    );
  }

  // Сбрасываем номер авто
  resetLicensePlate(): void {
    this.formService.form.get('carData')?.get('licensePlate')?.reset();
    this.formService.form.get('carData')?.get('licensePlate')?.clearValidators();
  }

  get isShowPrivacyLicense() {
    return this.accordionService.accordionGroups?.find((item) => item.isOpen
        && (item.panelClass === 'contactsGroup'));
  }
}
