<div class="card h-auto shadow-0 border-0 custom-rounded">
  <div class="card-body p-3 p-sm-4">
    <div class="row align-items-center">
      <div class="col">
        <div class="main-title-item mb-2 text-wrap">{{ title }}</div> <!-- Вызов сигнала -->
        <div class="second-title-item text-wrap">{{ message }}</div> <!-- Вызов сигнала -->
      </div>
    </div>
    <div class="row justify-content-center mt-3 mt-sm-4">
      <div class="col-12 col-sm-6">
        <button
          type="button"
          class="btn btn-primary w-100"
          (click)="modalRef.hide()">
          Продолжить
        </button>
      </div>
      <div class="col-12 col-sm-6">
        @if (isShowBtnNewPolicy) {
          <button
            type="button"
            class="btn btn-outline-primary w-100 mt-2 mt-sm-0"
            (click)="navigateToMainPage()">
            @if (isRedirectProcessing) {
              <div class="spinner-border payment-loader" role="status">
                <span class="visually-hidden">Загрузка...</span>
              </div>
            } @else {
              Купить новый
            }
          </button>
        }
        @if (isInvalidForm) {
          <button
            type="button"
            class="btn btn-outline-primary w-100 mt-2 mt-sm-0"
            (click)="navigateToFormPage()">
            Обновите данные
          </button>
        }
      </div>
    </div>
  </div>
</div>
