import {Component, OnDestroy} from '@angular/core';
import {OsagoService} from "../../shared/services/osago.service";
import {NavigationService} from "../../shared/services/navigation.service";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {PolicyDataComponent} from "../../shared/components/policy-data/policy-data.component";

@Component({
  selector: 'app-data-checking',
  standalone: true,
  templateUrl: './data-checking.component.html',
  imports: [
    PolicyDataComponent
  ],
  styleUrls: ['./data-checking.component.scss']
})
export class DataCheckingComponent implements OnDestroy {

  constructor(public readonly osagoService: OsagoService,
              private readonly navigationService: NavigationService,
              private readonly ym: YandexMetrikaService) {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.OfferSelectionPage);
    this.osagoService.isShowEditInfo = true;
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {

  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.osagoService.isShowEditInfo = false;
  }
  // --------------------------------------------------------------------------

  // Переход на страницу офферов
  public navigateToOffersPage(): void {
    this.navigationService.navigate('offers');
  }
  // Переход на страницу создания полиса
  public navigateToCreatePolicy(): void {
    this.navigationService.navigate('create-policy');
  }

}
