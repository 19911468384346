import {Component, inject, OnInit, signal} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Router, RouterLink} from "@angular/router";
import {ROUTES} from "../../enums/routes.enum";
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: 'app-error-message',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './error-message.component.html',
  styleUrl: './error-message.component.scss'
})
export class ErrorMessageComponent implements OnInit {
  public modalRef = inject(BsModalRef);
  private navigationService = inject(NavigationService);

  title?: string;
  message?: string;
  isShowBtnNewPolicy?: boolean = false;
  isInvalidForm?: boolean = false;
  isRedirectProcessing?: boolean = false;

  ngOnInit(): void {
    this.modalRef.setClass('modal-dialog-centered');
  }

  // Переход на главную
  public navigateToMainPage(): void {
    this.modalRef.hide();
    this.isRedirectProcessing = true;
    window.location.href = 'https://alfa-strah.com/forma-osago';
    setTimeout(() => {
      this.isRedirectProcessing = false;
    }, 4000);
  }
  // Переход на форму
  public navigateToFormPage(): void {
    this.modalRef.hide();
    this.navigationService.navigate(ROUTES.Form, { fromFastProlongation: true });
  }
}
