import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EMPTY, Observable, of, tap} from "rxjs";
import {environment} from "@environment/environment";
import {SettingsService} from "src/app/shared/services/settings.service";
import {catchError} from "rxjs/operators";
import {YandexMetrikaGoalsEnum} from "src/app/shared/enums/yandex-metrika-goals.enum";
import {YandexMetrikaService} from "src/app/shared/services/yandex-metrika.service";
import {LoggingService} from "src/app/shared/services/loggingService";

@Injectable({
  providedIn: 'root'
})
export class AlfaIdService {
  private ym = inject(YandexMetrikaService);
  private loggingService = inject(LoggingService);
  private http = inject(HttpClient);
  private settingsService = inject(SettingsService);

  // Индикатор загрузки
  public isLoading!: boolean;

  // Имя авторизированного пользователя с AlfaID
  public nameFromAlfaId!: string;

  // Получаем ссылку на авторизацию по AlfaID
  public getAlfaIdUrl(forProlongation: boolean = false): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      forProlongation
    };
    return this.http.post(environment.apiUrl + 'enrichment/GetAlfaIdUrl', request)
      .pipe(
        tap((res: any) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetAlfaIdUrlResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса enrichment/GetAlfaIdUrl', error);
          this.loggingService.error('Ошиба запроса enrichment/GetAlfaIdUrl');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetAlfaIdUrl);
          return of(error);
        })
      );
  }

  public getAlfaUseFromCode(forProlongation: boolean = true): Observable<any> {
    // Создаем ссылку
    const url: URL = new URL(window.location.href);
    // Получаем параметры
    const params: URLSearchParams = url.searchParams;

    // Если есть code в параметрах url, то используем его
    const code: string | null = params.get('code');
    const applicationId: string | null = params.get('applicationId');

    if (!code || !applicationId) {
      return EMPTY;
    }

    const request = {
      apiKey: this.settingsService.apiKey,
      code,
      applicationId,
      forProlongation
    };
    return this.http.post(environment.apiUrl + 'enrichment/Alfaid', request)
      .pipe(
        tap((res: any) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.AlfaidResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса enrichment/Alfaid', error);
          this.loggingService.error('Ошиба запроса enrichment/Alfaid');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorAlfaid);
          return of(error);
        })
      );
  }

}
