import {
  Component, ElementRef,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl, NG_VALIDATORS, Validator, FormControl, FormsModule
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FormService} from '../../services/form.service';
import {ValidationService} from '../../services/validation.service';
import {BsDatepickerConfig, BsDatepickerDirective, BsDatepickerModule, BsLocaleService} from "ngx-bootstrap/datepicker";
import {defineLocale, ruLocale} from "ngx-bootstrap/chronos";
import moment from "moment-mini";
import * as IMask from 'imask';
import {IMaskDirective} from 'angular-imask';
import {MASKS} from "../../constants/masks";
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {PopoverModule} from "ngx-bootstrap/popover";
import {FormFieldErrorComponent} from "../form-field-error/form-field-error.component";

@Component({
  selector: 'app-form-date-field',
  standalone: true,
  templateUrl: './form-date-field.component.html',
  styleUrls: ['./form-date-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormDateFieldComponent),
    multi: true,
  },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormDateFieldComponent),
      multi: true
    }],
  imports: [
    NgClass,
    FormsModule,
    IMaskDirective,
    PopoverModule,
    NgIf,
    NgOptimizedImage,
    FormFieldErrorComponent,
    BsDatepickerModule
  ]
})
export class FormDateFieldComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {

  // Заголовок
  @Input() label!: string;
  // Показываем календарь
  @Input() isShowCalendar: boolean = false;
  // Только для чтения
  @Input() isReadonly!: boolean;
  @Input() disabled: boolean = false;

  @ViewChild('inputDate', {static: false}) inputDate!: ElementRef;
  @ViewChild(BsDatepickerDirective, {static: false}) datepicker?: BsDatepickerDirective;
  @ViewChild('controlElm', {read: IMaskDirective}) controlElm!: IMaskDirective<IMask.MaskedPattern>;

  // Настройки календаря
  public bsConfig?: Partial<BsDatepickerConfig>;
  // Локализавция календаря
  private locale = 'ru';
  // Контрол изменяемого input
  public value: string = '';
  public inputControl: UntypedFormControl = new UntypedFormControl(null);
  public inputModel!: any;
  // Контрол
  public control!: UntypedFormControl;
  // Имя контрола
  public name!: string;
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Сообщения валидации
  public messages: { [key: string]: any } = {};
  // Флаг, идет загрузка списка
  public isLoading = false;
  // Паттерн - только числа
  public pattern = '[0-9]';
  // Показываем клавиатуру, текст или только цифры
  public inputmode: string = 'text';
  isInvalid = false;

  // Маска для контролла даты
  public mask = {
    mask: Date,
    lazy: true,
    placeholderChar: '_',
    autofix: true,
    overwrite: false,
    signed: false,
  };
  // Максимальная дата для выбора в календаре
  public maxDate!: Date;
  // Минимальная дата для выбора в календаре
  public minDate!: Date;
  // Минимальная дата для выбора в календаре
  public minDateYear!: Date;
  // Используется popover
  public usePopover = false;
  // Контент для popover
  public popoverContent = ``;

  // Вызовем когда значение изменится
  private onTouched: any = () => {
  };

  // Вызовем при любом действии пользователя с контроллом
  private onChange: any = () => {
  };

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly validationService: ValidationService,
              private readonly localeService: BsLocaleService) {
    defineLocale('ru', ruLocale);
    localeService.use(this.locale);
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-default',
      showWeekNumbers: false
    });
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    const injectedControl = this.injector.get(NgControl);
    this.name = injectedControl.name + 'controlName';

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }

    if (propertyControl.maxDate === 'today' && this.bsConfig) {
      this.maxDate = moment().toDate();
      this.bsConfig.maxDate = this.maxDate;
    }

    if (propertyControl.maxDate && propertyControl.maxDate !== 'today' && this.bsConfig) {
      this.maxDate = moment().add(Number(propertyControl.maxDate), "days").toDate();
      this.bsConfig.maxDate = this.maxDate;
    }

    if (propertyControl.minDate === 'today' && this.bsConfig) {
      this.minDate = moment().toDate();
      this.bsConfig.minDate = this.minDate;
    }

    if (propertyControl.minDate && propertyControl.minDate !== 'today' && this.bsConfig) {
      this.minDate = moment().add(Number(propertyControl.minDate), "days").toDate();
      this.bsConfig.minDate = this.minDate;
    }

    if (propertyControl.minDateYear && propertyControl.minDateYear !== 'today' && this.bsConfig) {
      this.minDateYear = moment().add(Number(propertyControl.minDateYear), "years").toDate();
      this.bsConfig.minDate = this.minDateYear;
    }

    if (propertyControl?.usePopover) {
      this.usePopover = propertyControl?.usePopover;
      this.popoverContent = propertyControl?.popoverContent!;
    }

    if (propertyControl?.inputmode) {
      this.inputmode = propertyControl?.inputmode;
    }

    if (propertyControl?.maskName) {
      MASKS.filter((item: any) => item.systemName === propertyControl?.maskName)
        .map((item) => this.mask = item.mask);
    }
    if (propertyControl?.mask) {
      this.mask.mask = propertyControl?.mask
      this.controlElm?.maskRef?.updateOptions(propertyControl?.mask);
    }

    this.validationService.setControlValidation(propertyControl, this.control);

    return;
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  // Сохраняем обратный вызов для изменений
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }

  public validate(c: FormControl) {
    if (!this.value) {
      this.isInvalid = c.touched && c.invalid;
      return {
        customInputError: 'text'
      };
    }
    this.isInvalid = false;
    return null;
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Событие при выходи из контрола
  public blurControl(event: any): void {
    this.controlElm.maskRef?.updateOptions({
      lazy: true
    });
  }

  // Фокус на контроле
  public focusDateControl(event: any): void {
    this.controlElm.maskRef?.updateOptions({
      lazy: false
    });
  }

  public changeDate(event: any): void {
    const date = moment(event);
    const formattedDate = date.format("DD.MM.YYYY");
    this.updateValue(formattedDate);
  }
}
