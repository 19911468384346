<div class="card mt-2 border-light h-auto shadow-none custom-rounded offer">
  <div class="card-body p-3 p-sm-4">
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="m-0 d-flex align-items-center">
          <img src="./assets/images/partner-logo/{{osagoService.selectedOffer?.insurerType}}-logo.svg"
               class="insurance-company-logo" [alt]="osagoService.selectedOffer?.insurerName">
        </div>
      </div>
      <div class="col p-0">
        <div class="col px-0 px-sm-2 d-flex flex-column justify-content-center align-items-start">
          <div class="insurance-company-title mb-0 d-inline-block text-truncate">
            @if (paymentLink) {
              Полис готов к оплате
            } @else {
              {{ osagoService.selectedOffer?.insurerName }}
            }
          </div>
          <div class="insurance-company-second-title text-secondary gray-string">
            @if (paymentLink) {
              Мы отправим его вам на почту
            } @else {
              Компания осуществляет проверку данных
            }
          </div>
        </div>
      </div>
      @if (paymentLink) {
        <div class="col-auto">
          <app-price [premiumAmount]="osagoService.selectedOffer?.premiumAmount!"
                     [installmentFirstPay]="osagoService.selectedOffer?.installmentFirstPay!"></app-price>
        </div>
      } @else {
        <div class="col-auto">
          <app-price [premiumAmount]="osagoService.selectedOffer?.premiumAmount!"
                     [installmentFirstPay]="osagoService.selectedOffer?.installmentFirstPay!"></app-price>
        </div>
      }
      <div class="col-12 mt-3 mt-lg-0 col-lg-auto d-block d-lg-flex text-end">
        <div class="row">
          <!--          TODO вернуть после того как сделаем возврат клиент на стейт оффер -->
          @if (environment.partnerSystemName !== 'yafuel'
          && environment.partnerSystemName !== 'alfa'
          && environment.partnerSystemName !== 'ipSemenov') {
            <div class="col-12 col-lg">
              <app-draft></app-draft>
            </div>
          }
          <div class="col-12 col-lg-auto mt-3 mt-lg-0">
            @if (paymentLink) {
              <a [href]="osagoService.paymentLink" target="_blank"
                 class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                 (click)="pay()">
                @if (isShowPaymentBtn) {
                  Перейти к оплате
                } @else {
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                }
              </a>
            } @else {
              @if (fromFastProlongation && !isPaymentCountdownStarted) {
                <button type="button"
                        (click)="onCreatePolicy()"
                        class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center">
                  Создать полис
                </button>
              } @else {
                <button type="button"
                        class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                        [disabled]="true">
                  Создаем полис
                  <span class="countdown-block">{{ paymentCountdown }}</span>
                </button>
              }
            }
          </div>
        </div>
      </div>
    </div>

    <app-upsales [offer]="osagoService.selectedOffer!"
                 [isDisabled]="!fromFastProlongation || isPaymentCountdownStarted"></app-upsales>

  </div>
</div>

<app-error-upsale></app-error-upsale>
