import { Component, OnInit} from '@angular/core';
import {ContentService} from "../../shared/services/content.service";
import {CacheService} from "../../shared/services/cache.service";
import {FormService} from "../../shared/services/form.service";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {TestComponent} from "../../shared/components/test/test.component";
import {EntityIndividualComponent} from "./entity-individual/entity-individual.component";
import {LicenseComponent} from "./license/license.component";
import {CacheListComponent} from "./cache-list/cache-list.component";
import {AppService} from "../../shared/services/app.service";
import { SettingsService } from 'src/app/shared/services/settings.service';
import {JsonPipe} from "@angular/common";
import { OnExecuteErrorData, RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaLoaderService, RecaptchaSettings, ReCaptchaV3Service } from 'ng-recaptcha-2';
import { switchMap, take } from 'rxjs';
import { OsagoService } from 'src/app/shared/services/osago.service';
import { LoggingService } from 'src/app/shared/services/loggingService';
import {environment} from "@environment/environment.dev";

@Component({
  selector: 'app-main',
  standalone: true,
  templateUrl: './main.component.html',
  imports: [
    TestComponent,
    EntityIndividualComponent,
    LicenseComponent,
    CacheListComponent,
    JsonPipe
  ],
  providers: [
    {provide: RECAPTCHA_SETTINGS, useValue: {size: 'invisible'} as RecaptchaSettings},
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeqedIbAAAAAKBil2D4gh6UjKUEIOV58Xmwf_hi'},
    ReCaptchaV3Service,
    RecaptchaLoaderService
  ],
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(public readonly contentService: ContentService,
              public readonly cacheService: CacheService,
              public readonly formService: FormService,
              public readonly appService: AppService,
              public readonly settingsService: SettingsService,
              private readonly ym: YandexMetrikaService,
              private readonly recaptchaV3Service: ReCaptchaV3Service,
              private readonly osagoService: OsagoService,
              private readonly loggingService: LoggingService) {
  }

  protected readonly localStorage = localStorage;
  public urlParams = this.appService.getUrlParams();

  public ngOnInit() {
    this.sendDataToYandexMetrika();
    this.initRecaptcha();
  }

  private sendDataToYandexMetrika(): void {
    const urlParams = this.appService.getUrlParams();

    const yandexMetrikaParams = {
      applicationId: this.formService.form.get('applicationId')?.value,
      clientId: this.settingsService.clientId,
      applicationType: this.formService.form.get('applicationType')?.value,
      agentId: this.formService.form.get('agentId')?.value,
      isJuridical: this.formService.form.get('isJuridical')?.value,
      src: urlParams.src,
      urlParams: urlParams,
      isProlongation: urlParams.isProlongation,
      code: urlParams.code,
    }

    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.MainPage, yandexMetrikaParams);
  }

  private initRecaptcha() : void {
    if (!environment.production) {
      this.loggingService.internalError("test log");
    }
    try {
      this.recaptchaV3Service.onExecuteError
        .subscribe((data: OnExecuteErrorData) => this.loggingService.debug("recaptcha error", data));

      this.recaptchaV3Service.execute('start')
        .pipe(
          take(1),
          switchMap((captcha: string) => this.osagoService.saveCaptcha(captcha))
        )
        .subscribe({
          next: () => { },
          error: (e: any) => { this.loggingService.debug("recaptcha error", e); }
      })
    } catch (e) {
      this.loggingService.debug("recaptcha error", e);
    }
  }
}
