import {Component, inject} from '@angular/core';
import {FormService} from "../../services/form.service";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {OsagoService} from "../../services/osago.service";
import {RouterLink} from "@angular/router";
import {AccordionService} from "../../services/accordion.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    BsDropdownModule,
    RouterLink
  ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent {

  private formService = inject(FormService);
  private osagoService = inject(OsagoService);
  private accordionService = inject(AccordionService);

  // Добавялем водителя
  public addDriver(driverNumber: number): void {
    const openGroup = this.accordionService.accordionGroups?.find(group => group?.isOpen === true);

    if (openGroup) {
      const panelClass = openGroup.panelClass;
      const match = panelClass.match(/driverGroup(\d+)/);
      if (match && match[1]) {
        const driverIndex = parseInt(match[1], 10);
        if (this.formService.drivers.at(driverIndex)) {
          const driverControl = this.formService.drivers.at(driverIndex);
          const {
            driverFirstName,
            driverLastName,
            oldDriverLastName,
            driverMiddleName,
            driverBirthDate,
            rememberOnlyYear,
            driverLicense,
            oldLicense,
            experienceDate,
            experienceYear,
            driverIsOwner,
            driverIsInsurer
          } = (driverControl as FormGroup)?.controls;

          if (driverNumber === 1) {
            driverLastName.setValue('Иванов', { emitEvent: true });
            driverFirstName.setValue('Иван', { emitEvent: true });
            driverMiddleName.setValue('Иванович', { emitEvent: true });
            driverBirthDate.setValue('01.01.1991', { emitEvent: true });
            experienceDate.setValue('01.01.2011', { emitEvent: true });
            driverLicense.setValue('1111111111', { emitEvent: true });
          }

          if (driverNumber === 2) {
            driverLastName.setValue('Петров', { emitEvent: true });
            driverFirstName.setValue('Петр', { emitEvent: true });
            driverMiddleName.setValue('Петрович', { emitEvent: true });
            driverBirthDate.setValue('02.02.1992', { emitEvent: true });
            experienceDate.setValue('02.02.2012', { emitEvent: true });
            driverLicense.setValue('2222222222', { emitEvent: true });
          }

          if (driverNumber === 3) {
            driverLastName.setValue('Сидоров', { emitEvent: true });
            driverFirstName.setValue('Сидр', { emitEvent: true });
            driverMiddleName.setValue('Сидорович', { emitEvent: true });
            driverBirthDate.setValue('03.03.1993', { emitEvent: true });
            experienceDate.setValue('03.03.2013', { emitEvent: true });
            driverLicense.setValue('3333333333', { emitEvent: true });
          }

          if (driverNumber === 4) {
            driverLastName.setValue('Михайлов', { emitEvent: true });
            driverFirstName.setValue('Михаил', { emitEvent: true });
            driverMiddleName.setValue('Михайлович', { emitEvent: true });
            driverBirthDate.setValue('04.04.1994', { emitEvent: true });
            experienceDate.setValue('04.04.2014', { emitEvent: true });
            driverLicense.setValue('4444444444', { emitEvent: true });
          }

          if (driverNumber === 4) {
            driverLastName.setValue('Соколов', { emitEvent: true });
            driverFirstName.setValue('Валентин', { emitEvent: true });
            driverMiddleName.setValue('Владимирович', { emitEvent: true });
            driverBirthDate.setValue('05.05.1995', { emitEvent: true });
            experienceDate.setValue('05.05.2015', { emitEvent: true });
            driverLicense.setValue('5555555555', { emitEvent: true });
          }

          console.log(`Данные водителя с индексом ${driverIndex} добавлены`);
        } else {
          console.error(`Группа водителей с индексом ${driverIndex} не найдена`);
        }
      }
    }
  }

  public generateCarNumber() {
    const mask = 'X YYY ZZ AAA';
    const blocks = {
      X: {
        enum: ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
      },
      YYY: {
        mask: '000',
      },
      ZZ: {
        enum: ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
      },
      AAA: {
        mask: '***',
      }
    };

    let carNumber = mask;

    // Заменяем X
    carNumber = carNumber.replace('X', blocks.X.enum[Math.floor(Math.random() * blocks.X.enum.length)]);

    // Заменяем YYY
    carNumber = carNumber.replace('YYY', () => Math.floor(100 + Math.random() * 900).toString());

    // Заменяем ZZ
    carNumber = carNumber.replace('ZZ', () => {
      return blocks.ZZ.enum[Math.floor(Math.random() * blocks.ZZ.enum.length)] +
        blocks.ZZ.enum[Math.floor(Math.random() * blocks.ZZ.enum.length)];
    });

    // Заменяем AAA
    carNumber = carNumber.replace('AAA', () => '799');

    this.formService.licenseFormGroup?.get('licensePlate')?.setValue(carNumber);
  }

}
