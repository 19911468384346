@for (upsale of osagoService.selectedOffer!.upsales; track upsale) {
  @if (upsale.isSelected && upsale?.isApproved === false) {
    <div class="card mt-3 mb-4 h-auto border-0 custom-rounded p-0 p-sm-2">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <img alt="" height="48" width="48"
                 loading="lazy"
                 ngSrc="./assets/images/offers-icons/empty-offers.svg">
          </div>
          <div class="col">
            <div class="main-title-item mb-3">Невозможно включить "{{upsale.name}}" в покупку</div>
            <div class="second-title-item">К сожалению, страховая компания не подтвердила создание полиса "{{upsale.name}}",
              но вы можете оплатить ОСАГО перейдя по ссылке</div>
          </div>
        </div>
      </div>
    </div>
  }
}
